import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { Avatar as AntAvatar, Spin, AvatarProps as AntAvatarProps } from 'antd';
import { useEffect, useState, useMemo, memo } from 'react';

interface AvatarProps extends AntAvatarProps {
  src?: string;
  customText?: string;
  shape?: 'circle' | 'square';
  username?: string;
  superSize?: boolean;
  containerClassName?: string;
  initialsLength?: number;
}

export const Avatar = memo((props: AvatarProps) => {
  const {
    src,
    superSize,
    customText,
    username,
    containerClassName,
    initialsLength = 2,
    shape = 'circle',
    ...rest
  } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const style = useMemo(
    () => (superSize ? { width: 64, height: 64, fontSize: 20 } : {}),
    [superSize],
  );

  const initials = useMemo(() => {
    if (username) return getInitials(username);
    return customText?.slice(0, initialsLength);
  }, [username, customText, initialsLength]);

  useEffect(() => {
    if (!src || !isValidURL(src)) {
      setIsValid(false);
      setIsLoaded(true);
      return;
    }

    setIsLoaded(false);
    setIsValid(true);

    const img = new Image();
    img.src = src;
    img.onload = () => setIsLoaded(true);
    img.onerror = () => {
      setIsValid(false);
      setIsLoaded(true);
    };
  }, [src]);

  const avatarContent = () => {
    if (isValid && !isLoaded) {
      return (
        <AntAvatar
          icon={<Spin indicator={<LoadingOutlined spin />} />}
          shape={shape}
          style={style}
          {...rest}
        />
      );
    }

    if (isValid && isLoaded) {
      return <AntAvatar shape={shape} src={src} style={style} {...rest} />;
    }

    if (initials) {
      return (
        <AntAvatar shape={shape} style={style} {...rest}>
          <span className="font-bold">{initials.toUpperCase()}</span>
        </AntAvatar>
      );
    }

    return <AntAvatar icon={<UserOutlined />} shape={shape} style={style} {...rest} />;
  };

  return <div className={containerClassName}>{avatarContent()}</div>;
});

Avatar.displayName = 'CustomAvatar';

// helper functions
function getInitials(username: string) {
  if (!username) return null;
  const nameParts = username.trim().split(' ');
  if (nameParts.length < 2) return null;

  return (
    nameParts[0].charAt(0).toUpperCase() + nameParts[nameParts.length - 1].charAt(0).toUpperCase()
  );
}

const isValidURL = (src: string | undefined) => {
  if (!src) return false;
  try {
    new URL(src);
    return true;
  } catch {
    return false;
  }
};
